import React, { Component } from "react";
import { Link, navigate } from "gatsby";
import styled from "styled-components";
import moment from "moment";
import Img from "gatsby-image";
import { colors } from "../colors/colors";

const EventContainer = styled.div`
  ::after {
    content: "";
    width: 80%;
    height: 1px;
    margin: 5px auto;
    background-color: rgba(255, 255, 255, 0.1);
    display: block;
  }
`;

const EventGroup = styled.div`
  display: grid;
  padding: 15px;
  grid-gap: 15px;
  grid-template-columns: 120px auto;
  justify-items: left left left;
  transition: 0.4s ease-out;

  @media (max-width: 500px) {
    grid-template-columns: 80px auto;
  }

  :hover {
    transform: translateX(15px);
    opacity: 0.9;
  }
`;

const EventFlyer = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 2px;
  object-fit: cover;
  margin: 0;

  @media (max-width: 500px) {
    width: 80px;
    height: 80px;
  }
`;

const EventInfo = styled.div``;

const EventTitle = styled.h1`
  font-family: "bebasbold";
  font-size: 30px;
  line-height: 100%;
  margin: 0 0 5px 0;
  transition: 0.4s ease-out;

  ${EventGroup}:hover & {
    color: ${colors.red};
  }
`;

const EventDate = styled.h2`
  text-transform: uppercase;
  font-size: 10px;
  font-weight: bolder;
  color: white;
  margin: 0;
`;
const Actions = styled.div`
  display: flex;
  flex-direction: row;
`;
const Button = styled.button`
  margin-right: 10px;
  margin-top: 15px;
  color: ${colors.red};
  border-color: ${colors.red};
  font-family: "Tanker";
  text-transform: uppercase;
  border: 3px solid $main;
  background: transparent;
  padding: 5px 12px;
  font-size: 16px;
  cursor: pointer;
  transition: 0.3s ease;
  outline: none;

  :last-child {
    margin-right: 0;
  }

  @media (max-width: 500px) {
    font-size: 12px;
  }

  :hover {
    color: white;
    background-color: rgb(230, 41, 41);
    border: 1px solid $main;
  }

  :focus {
    outline: none;
  }
`;
class EventTicketItem extends Component {
  render() {
    return (
      <EventContainer>
        <EventGroup>
          <EventFlyer>
            <Img
              style={{
                width: "100%",
                height: "100%",
                margin: 0,
              }}
              fadeIn
              objectFit="cover"
              fluid={this.props.fluid}
            />
          </EventFlyer>

          <EventInfo>
            {this.props.url ? (
              <Link style={{ color: "inherit" }} to={this.props.url}>
                <EventTitle>{this.props.title}</EventTitle>
              </Link>
            ) : (
              <EventTitle>{this.props.title}</EventTitle>
            )}
            <EventDate>
              {moment(this.props.date).format("MMMM Do, YYYY")}{" "}
              {this.props.title === "Bacchanal Weekend (Combo)" &&
                "- February 9th, 2020"}
            </EventDate>
            <Actions>
              <Button onClick={() => navigate(`/events/${this.props.slug}`)}>
                View Event
              </Button>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={this.props.ticketLink}
              >
                <Button>Get Tickets</Button>
              </a>
            </Actions>
          </EventInfo>
        </EventGroup>
      </EventContainer>
    );
  }
}

export default EventTicketItem;
