import React, { Component } from "react";
import Layout from "../components/layout";
import SectionIntro from "../components/section-intro";
import styled from "styled-components";

import SEO from "../components/seo";
import EventTicketItem from "../components/event-ticket-item";
import moment from "moment";

const CalendarContainer = styled.div`
  max-width: 1000px;
  padding: 50px 3%;
  margin: 0 auto;
`;

class TicketsPage extends Component {
  state = {
    events: [],
  };
  componentDidMount() {
    //Function to order by date
    const data = this.props.data.allWordpressPost.edges;
    let sortedData = data.sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(b.node.acf.date) - new Date(a.node.acf.date);
    });
    sortedData = sortedData.filter(function (event) {
      //Return events withinn the next 3 months
      return new Date(event.node.acf.date) > moment().toDate();
    });

    this.setState({ events: sortedData.reverse() });
  }

  render() {
    const { events } = this.state;
    return (
      <Layout>
        <SEO
          title="Purchase Event Tickets"
          keywords={[
            `yup`,
            `life`,
            `FOC`,
            `Festival`,
            `Colour`,
            `Backyard`,
            `Melee`,
            `Events`,
            `Fete`,
            `Trinidad`,
            `Carnival`,
            `Party`,
            `Fete`,
          ]}
        />
        <SectionIntro
          title="Purchase Event Tickets"
          copy="Official ticket purchase links for our upcoming events"
        />
        <CalendarContainer>
          {events.map((event, index) => (
            <div key={index}>
              <EventTicketItem
                title={event.node.acf.name}
                description={event.node.acf.tagline}
                flyer={event.node.acf.flyer.source_url || ""}
                date={event.node.acf.date}
                slug={event.node.slug}
                fluid={event.node.acf.flyer.localFile.childImageSharp.fluid}
                ticketLink={event.node.acf.ticket_url}
              />
            </div>
          ))}
        </CalendarContainer>
      </Layout>
    );
  }
}

export default TicketsPage;

export const ticketsQuery = graphql`
  query ($category: String = "Event") {
    allWordpressPost(
      filter: {
        acf: { ticket_url: { ne: "" } }
        categories: { elemMatch: { name: { eq: $category } } }
      }
    ) {
      edges {
        node {
          title
          content
          slug
          acf {
            name
            flyer {
              source_url
              localFile {
                childImageSharp {
                  fluid(maxHeight: 150) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            date
            ticket_url
            hide
            tagline
          }
        }
      }
    }
  }
`;
